import React from 'react';
import Slider from "react-slick";
import axios from 'axios';
import {Helmet} from 'react-helmet';

import './Projects.scss';
import FooterFeatured from "../common/Footer-featured";
import FooterContacts from "../common/Footer-contacts";

class ProjectsOpen extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			meta: [],
			data: [],
			activeSlide: 0,
			activeSlide2: 0,
			activeSlideDesign: 0,
			activeSlideDesign2: 0,
			featuredSlider: [],
			designSlider: [],
			progressBarClass: '',
			progressBarDesignClass: '',
			isOpen: false,
		};

	}

	toggle = () => {
		this.setState({ isOpen: !this.state.isOpen });
	};

	componentDidMount() {
		this.setState({
			progressBarClass: 'start',
			progressBarDesignClass: 'start'
		});

		axios.get(window.API + this.props.match.url + '.html')
			.then(result => {

				this.setState({
					meta: result.data.meta,
					data: result.data.data,
					featuredSlider: result.data.data.top_gallery,
					designSlider: result.data.data.bottom_gallery,
				})
			})
			.catch(error => alert(error));

	}

	render() {
		let translate = this.props.translate;

		let settingsImg = {
			autoplay: false,
			infinite: true,
			autoplaySpeed: 4000,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			dots: true,
			pauseOnHover: false,
			customPaging: (i) => {
				return <span>{i>8?'':'0'}{i + 1} <span> <b>/</b> {this.state.featuredSlider.length > 10 ? '' : '0'}{this.state.featuredSlider.length}</span></span>
			},
			// beforeChange: (current, next) => this.setState({activeSlide: next, progressBarClass: ''}),
			// afterChange: current => this.setState({activeSlide2: current, progressBarClass: 'start'})
		};

		let settingsDesign = {
			autoplay: false,
			infinite: true,
			autoplaySpeed: 4000,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			dots: true,
			pauseOnHover: false,
			customPaging: (i) => {
				return <span>{i>8?'':'0'}{i + 1} <span> <b>/</b> {this.state.designSlider.length > 10 ? '' : '0'}{this.state.designSlider.length}</span></span>
			},
			// beforeChange: (current, next) => this.setState({activeSlideDesign: next, progressBarDesignClass: ''}),
			// afterChange: current => this.setState({activeSlideDesign2: current, progressBarDesignClass: 'start'})
		};

		return (
			<div className="projects__open">
				<Helmet>
					<title>{this.state.data.title}</title>
					<meta name="description" content={this.state.data.description}/>
					<meta name="keywords" content={this.state.data.keywords}/>

					<meta property="og:title" content={this.state.data.title}/>
					<meta property="og:description" content={this.state.data.description}/>
					<meta property="og:image" content={this.state.data.image}/>
					<meta property="og:url" content={window.location.href}/>
					<meta name="twitter:card" content="summary_large_image"/>
				</Helmet>

				<div className="project__info container__padding">
					<h1>{this.state.data.title}</h1>

					<div>
						<div className="stats">

							<div className="right-column">
								{this.state.data.info_location &&
								<div className="row">
									<p className="title">{translate.project_location}</p>
									<p className="description" dangerouslySetInnerHTML={{__html: this.state.data.info_location.replace(/\n/g," </br>")}}></p>
								</div>
								}

								{this.state.data.info_client &&
								<div className="row">
									<p className="title">{translate.project_client}</p>
									<p className="description" dangerouslySetInnerHTML={{__html: this.state.data.info_client.replace(/\n/g," </br>")}}></p>

								</div>
								}

								{this.state.data.info_status &&
								<div className="row">
									<p className="title">{translate.project_status}</p>
									<p className="description" dangerouslySetInnerHTML={{__html: this.state.data.info_status.replace(/\n/g," </br>")}}></p>
								</div>
								}

								{this.state.data.info_size &&
								<div className="row">
									<p className="title">{translate.project_area}</p>
									<p className="description" dangerouslySetInnerHTML={{__html: this.state.data.info_size.replace(/\n/g," </br>")}}></p>
								</div>
								}

								{this.state.data.info_type &&
								<div className="row">
									<p className="title">{translate.project_project}</p>
									<p className="description" dangerouslySetInnerHTML={{__html: this.state.data.info_type.replace(/\n/g," </br>")}}></p>
								</div>
								}

								{this.state.data.info_sustainability &&
								<div className="row">
									<p className="title">{translate.project_sustainability}</p>
									<p className="description" dangerouslySetInnerHTML={{__html: this.state.data.info_sustainability.replace(/\n/g," </br>")}}></p>
								</div>
								}
							</div>

							<div className="left-column">
								{this.state.data.info_team &&
								<div className="row">
									<p className="title">{translate.project_team}</p>
									<p className="description" dangerouslySetInnerHTML={{__html: this.state.data.info_team}}></p>
								</div>
								}

								{this.state.data.info_partners &&
								<div className="row">
									<p className="title">{translate.project_partners}</p>
									<p className="description" dangerouslySetInnerHTML={{__html: this.state.data.info_partners.replace(/\n/g," </br>")}}></p>
								</div>
								}

								{this.state.data.info_visualizations &&
								<div className="row">
									<p className="title">{translate.project_visualizations}</p>
									<p className="description" dangerouslySetInnerHTML={{__html: this.state.data.info_visualizations.replace(/\n/g," </br>")}}></p>
								</div>
								}

								{this.state.data.info_photo &&
								<div className="row">
									<p className="title">{translate.project_photos}</p>
									<p className="description" dangerouslySetInnerHTML={{__html: this.state.data.info_photo.replace(/\n/g," </br>")}}></p>
								</div>
								}

								{this.state.data.info_prizes &&
								<div className="row">
									<p className="title">{translate.project_prize}</p>
									<p className="description" dangerouslySetInnerHTML={{__html: this.state.data.info_prizes.replace(/\n/g," </br>")}}></p>
								</div>
								}
							</div>
						</div>

						<div className="text">
							<div className={'display-text-group' + (this.state.isOpen ? ' more' : '')}
								 dangerouslySetInnerHTML={{__html: this.state.data.content}}></div>

							<p className={'read-more-button'} onClick={this.toggle}>
								{this.state.isOpen ? translate.common_read_less : translate.common_read_more}
							</p>
						</div>

					</div>
				</div>

				{this.state.featuredSlider.length > 0 &&
				<div className="project__slider slider--dark">
					<Slider {...settingsImg}>
						{this.state.featuredSlider.map((item, index) =>
							<div key={index}>
								<div className="slider__item">
									<a href={item.original} rel="noopener noreferrer" target="_blank">
                                        <img src={item.img} alt=""/>
                                    </a>
								</div>
							</div>
						)}
					</Slider>

					<div className={'progress-bar ' + (this.state.progressBarClass) + ' container__margin'}></div>
				</div>
				}

				{this.state.designSlider.length > 0 &&
				<div className="project__slider dark">
					<Slider {...settingsDesign}>
						{this.state.designSlider.map((item, index) =>
							<div key={index}>
								<div className="slider__item">
                                    <a href={item.original} rel="noopener noreferrer" target="_blank">
                                        <img src={item.img} alt=""/>
                                    </a>
								</div>
							</div>
						)}
					</Slider>

					<div className={'progress-bar ' + (this.state.progressBarDesignClass) + ' container__margin'}></div>
				</div>
				}

				<FooterContacts
					sections={this.props.sections}
					translate={this.props.translate}
				/>
			</div>
		);
	}
}

export default ProjectsOpen;
