import React from 'react';
// import axios from 'axios';

import './Not-found.scss';
import HeaderLogo from "../common/Logo";
import {NavLink} from "react-router-dom";

import notFound from "../../assets/img/404.svg"

class NotFound extends React.Component {
	constructor(props) {
		super(props);
		this.state = {}
	}
	
	
	render() {
		
		return (
			<div>
				
				<HeaderLogo/>
				
				<div className="not-found container__padding">
					<img src={notFound} alt="" />
					<span>Lapa nav atrasta</span>
					<NavLink to={'/'}> uz Sākumu </NavLink>
				</div>
			 
			</div>
		);
	}
}

export default NotFound;
