import React from 'react';
import TrackVisibility from "react-on-screen";

import './Footer.scss';

class FooterContacts extends React.Component {
	constructor(props) {
		super(props);
		this.state = {}
	}


	render() {
		let contacts = false;
		let translate = this.props.translate;
		let sections = this.props.sections;

		sections.map((section) => {
			if (section.model === 'contacts') {
				contacts = section.contacts;
			}
			return contacts;
		})

		return (
			<TrackVisibility partialVisibility>
				{
					({isVisible}) =>
						<div>
							{contacts &&
							<div
								className={'footer__contacts container__padding animated ' + (isVisible ? 'fadeInUp' : '')}>
								<h2>{translate.contacts_title}</h2>
								<div className="item">
									<div>
										<p>{translate.contacts_phone}</p>
										<ul>
											{contacts.phones.map((item, i) => {
												return (
													<li key={i}>
														<a href={'tel:' + item}>{item}</a>
													</li>
												);
											})}
										</ul>
									</div>

									<div>
										<p>{translate.contacts_email}</p>
										<a href={'mailto:' + contacts.email}>{contacts.email}</a>
									</div>

                                    <div>
										<p>{translate.contacts_location}</p>
										<p className="address">{contacts.address}</p>
									</div>
								</div>
							</div>
							}
						</div>
				}
			</TrackVisibility>
		);
	}
}

export default FooterContacts;
