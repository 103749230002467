import React from 'react';

import './Footer.scss';
import {NavLink} from "react-router-dom";
import TrackVisibility from "react-on-screen";

class FooterFeatured extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			visible: 4,
		}
		this.loadMore = this.loadMore.bind(this);
	}
	
	loadMore() {
		this.setState((prev) => {
			return {visible: prev.visible + 4};
		});
	}
	
	render() {
		let projects = false;
		let translate = this.props.translate;
		let sections = this.props.sections;
		
		sections.map((section) => {
			if (section.model === 'projects') {
				projects = section.projects;
			}
			return projects;
		})
		
		return (
			<TrackVisibility partialVisibility>
				{
					({isVisible}) =>
						<div className={'footer__featured container__padding animated ' + (isVisible ? 'fadeInUp' : '')}>
							<h1>{translate.featured_projects_title}</h1>
							
							<div>
								<div className="text">
									<p>{translate.featured_projects_text}</p>
								</div>
								
								<div className="links">
									<ul>
										{projects.slice(0, this.state.visible).map(featured =>
											<li key={featured.id} className="fade-in">
												<NavLink to={featured.link}>
													{featured.title}
												</NavLink>
												<p>{featured.category},{translate.project_accomplished} — {featured.accomplished}</p>
											</li>
										)}
									</ul>
									
									{this.state.visible < projects.length &&
									<p onClick={this.loadMore} className="load-more">
										{translate.more_projects}
									</p>
									}
								</div>
							</div>
						</div>
				}
			</TrackVisibility>
		);
	}
}

export default FooterFeatured;
