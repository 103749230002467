import React from 'react';
import axios from 'axios';
import {Helmet} from 'react-helmet';

import './Projects.scss';
import ActionBlock from "../common/Action";
import FooterContacts from "../common/Footer-contacts";
import ProjectsElements from "../common/ProjectsElements";

class Projects extends React.Component {
	constructor() {
		super();
		this.state = {
			meta: [],
			featured: [],
			projects: [],
			categories: [],
      restoreScrollPosition: false,
		};
    this.isSafari = this.isSafari.bind(this);
	}

	componentDidMount() {
    window.onpopstate = e => {
      this.setState({
        restoreScrollPosition: true,
      })
    }

		axios.get(window.API + this.props.match.url)
			.then(result => {
				this.setState({
					meta: result.data.meta,
					featured: result.data.featured,
					projects: result.data.projects,
					categories: result.data.categories
				})

        if (this.state.restoreScrollPosition || this.isSafari()) {
          setTimeout(function(){
            let scrollPos = localStorage.getItem('scrollPosition') || 0;
            window.scrollTo(0, scrollPos)
          },250)
        }
			})
			.catch(error => alert(error));
	}

  // Save scroll position
  componentWillUnmount() {
    localStorage.setItem('scrollPosition', document.body.scrollTop || document.documentElement.scrollTop);
  }

  isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

	render() {
		let about = false;
		let translate = this.props.translate;
		let sections = this.props.sections;

		sections.map((section) => {
			if (section.model === 'about') {
				about = section.about;
			}
			return about;
		})

		return (

						<div>
							<Helmet>
								<title>{this.state.meta.title}</title>
								<meta name="description" content={this.state.meta.description}/>
								<meta name="keywords" content={this.state.meta.keywords}/>

								<meta property="og:title" content={this.state.meta.title}/>
								<meta property="og:description" content={this.state.meta.description}/>
								<meta property="og:image" content={this.state.meta.image}/>
								<meta property="og:url" content={window.location.href}/>
								<meta name="twitter:card" content="summary_large_image"/>

							</Helmet>

							<div className={'projects__inside'}>
								<div className="projects__intro container__padding">
									<div>
										<h1>{this.state.meta.title}</h1>
										<p>{translate.projects_intro}</p>
									</div>
								</div>

								<ProjectsElements
									categories={this.state.categories}
									featured={this.state.featured}
									projects={this.state.projects}
									translate={this.props.translate}
                  restoreScrollPosition={this.state.restoreScrollPosition}
								/>

								<ActionBlock
									about={about}
								/>

								<FooterContacts
									sections={this.props.sections}
									translate={this.props.translate}
								/>

							</div>
						</div>

		);
	}
}

export default Projects;
