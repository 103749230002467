import React from 'react';
import {NavLink, withRouter} from "react-router-dom";
import './Menu.scss';


class Menu extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			menuopen: false,
			cssClass: '',
		}
	}

	componentWillMount() {
		// Listen for URL change and set menu CSS class
		this.setMenuClass(this.props.history.location.pathname);

		this.props.history.listen(() => {
			this.setMenuClass(this.props.history.location.pathname);
		});
	}

	setMenuClass = (pathname) => {
		if (pathname !== '/') {
			this.setState({
				cssClass: ' menu--dark'
			})
		}
	};

	_toggleMenu = () => {
		const currentState = this.state.menuopen;
		this.setState({menuopen: !currentState});
	}

	render() {
		let translate = this.props.translate;
		let sections = this.props.sections;

		return (
			<div className="navigation">
				<div className={'navigation__trigger' + this.state.cssClass + (this.state.menuopen ? ' open' : '')}>
					<ul className={this.state.menuopen ? ' show' : ''}>
						<li>
							<a href={this.props.base + '/lv'}>LV</a>
						</li>
						<li>
							<a href={this.props.base + '/en'}>EN</a>
						</li>
					</ul>

					<p onClick={this._toggleMenu}>
						{this.state.menuopen ? translate.menu_close : translate.menu_label}
					</p>
				</div>

				<div className={'navigation__content' + (this.state.menuopen ? ' open' : '')}>


					{sections.map((section, index) =>

						<div className={'navigation__link animated' + (this.state.menuopen ? ' fadeInUp delay'+index+'' : '')}
							 key={section.id}>

							<div className="link__name">
								<NavLink to={section.link} onClick={this._toggleMenu}>{section.title}</NavLink>
							</div>

							{section.model === 'projects' && section.projects.length > 0 &&
							<div className="link__info">
								<p>{translate.featured_projects_title}</p>
								<ul>
									{section.projects.map(item =>
										<li key={item.id}>
											<NavLink to={item.link} onClick={this._toggleMenu}>{item.title}</NavLink>
										</li>
									)}
								</ul>
							</div>
							}

							{section.model === 'contacts' &&
							<div className="link__info">
								<p>{section.contacts.address}</p>

								<ul>
									{section.contacts.phones.map((item, i) => {
										return (
											<li key={i}>
												<a href={'tel' + item}>{item}</a>
											</li>
										);
									})}
								</ul>
							</div>
							}
						</div>
					)}
				</div>

				<div className={'navigation__overlay ' + (this.state.menuopen ? ' open fade-in-fast' : '')}></div>
			</div>
		);
	}
}

export default withRouter(Menu);
