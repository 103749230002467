import React from 'react';

import './Privacy.scss';
import Cookies from "../../assets/img/privacy.svg";
import {NavLink} from "react-router-dom";

class PrivacyPopup extends React.Component {

	render() {
    let status    = this.props.status;
    let translate = this.props.translate;

		return (
      <div>
        {status === 0 &&
  			<div className="privacy">
  				<img src={Cookies} alt=""/>

  				<p>{translate.cookie_text}</p>

  				<div>
  					<button className="accept" onClick={this.props.acceptCookie}>
  						{translate.cookie_accept}
  					</button>

  					<NavLink to={'/'+this.props.language+'/'+translate.cookie_about_url}>
  						{translate.cookie_more}
  					</NavLink>
  				</div>
  			</div>
        }
      </div>
		);
	}
}

export default PrivacyPopup;
