import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import axios from 'axios';

import Menu from './components/common/Menu';
import ScrollToTop from './components/common/ScrollToTop';
import Home from './components/home/Home';
import Projects from './components/projects/Projects';
import About from './components/about/About';
import Contacts from './components/contacts/Contacts'
import ProjectsOpen from "./components/projects/ProjectOpen";
import Textdoc from './components/textdoc/Textdoc'
import PrivacyPopup from "./components/privacy/Privacy-popup";
import NotFound from "./components/common/Not-found";
import HeaderLogo from './components/common/Logo';

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			translate: [],
			sections: [],
			routes: [],
			language: 'lv',
			cookies: 1,
			base: '',
		}
		this.setTranslations = this.setTranslations.bind(this);
		this.setSections = this.setSections.bind(this);
		this.acceptCookie = this.acceptCookie.bind(this);
	}

	componentWillMount() {
		let ln = 'lv';
		if (window.location.pathname.includes('/en')) ln = 'en';
		this.setState({language: ln});
		this._loadData(ln);
	}

	_loadData = (ln) => {
		axios.get(window.API + '/' + ln + '/api/routes')
			.then(result => {
				this.setState({
					routes: result.data.routes,
					sections: result.data.sections,
					cookies: result.data.cookies,
					translate: result.data.translate
				});
			})
			.catch(error => alert(error));
	}

	setTranslations = (t) => {
		this.setState({translate: t});
	}

	setSections = (t) => {
		this.setState({sections: t});
	}

	acceptCookie() {
		this.setState({cookies: 1});
		axios.post(window.API + '/' + this.state.language + '/api/cookie');
	}

	render() {
		return (
			<div>
				<Router basename={this.state.base}>
					<ScrollToTop>
						<Menu
							sections={this.state.sections}
							translate={this.state.translate}
							base={this.state.base}
						/>

						<HeaderLogo lang={this.state.language}/>

						<Switch>
							<Route exact path="/"
										 render={(props) => <Home
                       language={this.state.language}
											 sections={this.state.sections}
											 translate={this.state.translate} {...props}/>}
							/>

							<Route exact path="/:lang"
										 render={(props) => <Home
											 language={this.state.language}
											 sections={this.state.sections}
											 translate={this.state.translate} {...props}/>}
							/>

							{this.state.routes.map(route => {
								if (route.component === 'About')
									return <Route exact path={route.path} key={route.id} render={(props) => <About
										sections={this.state.sections}
										translate={this.state.translate} {...props}/>}/>

								if (route.component === 'Textdoc')
									return <Route exact path={route.path} key={route.id} render={(props) => <Textdoc
										sections={this.state.sections}
										translate={this.state.translate} {...props}/>}/>

								if (route.component === 'Projects')
									return <Route exact path={route.path} key={route.id} render={(props) => <Projects
										lang={this.state.language}
										sections={this.state.sections}
										translate={this.state.translate} {...props}/>}/>

								if (route.component === 'Project')
									return <Route exact path={route.path} key={route.id} render={(props) => <ProjectsOpen
										sections={this.state.sections}
										translate={this.state.translate} {...props}/>}/>

								if (route.component === 'Contacts')
									return <Route exact path={route.path} key={route.id} render={(props) => <Contacts
										sections={this.state.sections}
										translate={this.state.translate} {...props}/>}/>

								if (route.component === '404')
									return <Route exact path={route.path} key={route.id} render={(props) => <NotFound
										sections={this.state.sections}
										translate={this.state.translate} {...props}/>}/>

								return <Route path="/" key="home" component={Home}/>
							})}
						</Switch>

						<PrivacyPopup
							language={this.state.language}
							translate={this.state.translate}
							acceptCookie={this.acceptCookie}
							status={this.state.cookies}
						/>
					</ScrollToTop>
				</Router>
			</div>
		)
	}
}

export default App;
