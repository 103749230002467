import React from 'react';
import axios from 'axios';
import {Helmet} from 'react-helmet';

import './About.scss';
import FooterContacts from "../common/Footer-contacts";
import FooterFeatured from "../common/Footer-featured";
import ActionBlock from "../common/Action";
import TrackVisibility from "react-on-screen";

class About extends React.Component {
	constructor() {
		super();
		this.state = {
			meta: [],
			about: [],
			sections: []
		};
	}
	
	componentDidMount() {
		
		axios.get(window.API + this.props.match.url)
			.then(result => {
				
				console.log(result)
				
				this.setState({
					meta: result.data.meta,
					about: result.data.about,
					sections: result.data.sections
				})
			})
			.catch(error => alert(error));
		
		localStorage.removeItem('project-link');
		localStorage.removeItem('project-link-large')
	}
	
	render() {
		
		return (
			<TrackVisibility partialVisibility>
				{
					({isVisible}) =>
						<div className={'animated ' + (isVisible ? 'fadeIn' : '')}>
							<Helmet>
								<title>{this.state.meta.title}</title>
								<meta name="description" content={this.state.meta.description}/>
								<meta name="keywords" content={this.state.meta.keywords}/>
								
								<meta property="og:title" content={this.state.meta.title}/>
								<meta property="og:description" content={this.state.meta.description}/>
								<meta property="og:image" content={this.state.meta.image}/>
								<meta property="og:url" content={window.location.href}/>
								<meta name="twitter:card" content="summary_large_image"/>
								
							</Helmet>
							
							<div className={'about__intro container__padding'}>
								<div>
									<h1>{this.state.meta.title}</h1>
									<p>{this.state.about.small_text}</p>
								</div>
							</div>
							
							<div className="about__content container__padding">
								<div className="image">
									<img src={this.state.about.image} alt=""/>
								</div>
								<div className="text" dangerouslySetInnerHTML={{__html: this.state.about.text}}></div>
							</div>
							
							<ActionBlock
								about={this.state.about}
							/>
							
							<FooterFeatured
								sections={this.props.sections}
								translate={this.props.translate}
							/>
							
							<FooterContacts
								sections={this.props.sections}
								translate={this.props.translate}
							/>
						
						</div>
					}
					</TrackVisibility>
					);
					}
					}
					
					export default About;
