import React from 'react';
import axios from 'axios';
import {Helmet} from 'react-helmet';

import './Contacts.scss';
import Map from "../common/Map"
import TrackVisibility from "react-on-screen";


class Contacts extends React.Component {
	constructor() {
		super();
		this.state = {
			meta: [],
		};
	}
	
	componentDidMount() {
		axios.get(window.API + this.props.match.url)
			.then(result => {
				this.setState({
					meta: result.data.meta,
				})
			})
			.catch(error => alert(error));
	}
	
	render() {
		let contacts = false;
		let translate = this.props.translate;
		let sections = this.props.sections;
		
		sections.map((section) => {
			if (section.model === 'contacts') {
				contacts = section.contacts;
			}
			return contacts;
		})
		
		return (
			<TrackVisibility partialVisibility>
				{
					({isVisible}) =>
						<div>
							<Helmet>
								<title>{this.state.meta.title}</title>
								<meta name="description" content={this.state.meta.description}/>
								<meta name="keywords" content={this.state.meta.keywords}/>
								
								<meta property="og:title" content={this.state.meta.title}/>
								<meta property="og:description" content={this.state.meta.description}/>
								<meta property="og:image" content={this.state.meta.image}/>
								<meta property="og:url" content={window.location.href}/>
								<meta name="twitter:card" content="summary_large_image"/>
								
							</Helmet>
							
							<div className={'contacts__intro animated ' + (isVisible ? 'fadeIn' : '')}>
								<div className='contacts__content container__padding'>
									<h1>{translate.contacts_title}</h1>
									
									<div>
										<div className="item">
											<p>{translate.contacts_location}</p>
											<p>{contacts.address}</p>
										</div>
										
										<div className="item">
											<p>{translate.contacts_phone}</p>
											<ul>
												{contacts.phones.map((item, i) => {
													return (
														<li key={i}>
															<a href={'tel:' + item}>{item}</a>
														</li>
													);
												})}
											</ul>
										</div>
										
										<div className="item">
											<p>{translate.contacts_email}</p>
											<a href={'mailto:' + contacts.email}>{contacts.email}</a>
										</div>
									</div>
								</div>
							</div>
							
							<Map
								lat={contacts.latitude}
								lng={contacts.longitude}
							/>
							
						</div>
				}
			</TrackVisibility>
		);
	}
}

export default Contacts;
