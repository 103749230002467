import React from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';

import './Textdoc.scss';
import FooterContacts from "../common/Footer-contacts";


class Textdoc extends React.Component {
  constructor(){
    super();
    this.state = {
      meta: [],
      data: []
    };
  }

  componentDidMount() {
    axios.get(window.API + this.props.match.url)
      .then(result => {
        this.setState({
          meta: result.data.meta,
          data: result.data.data
        })
      })
      .catch(error => this.setState({
        error
      }));
  }

	render() {

		return (
			<div>
        <Helmet>
          <title>{this.state.meta.title}</title>
          <meta name="description" content={this.state.meta.description} />
          <meta name="keywords" content={this.state.meta.keywords} />
        </Helmet>

				<div className="textdoc__content container__padding">
					<div className="small-container">
						<p className="date">{this.props.translate.last_edit} {this.state.data.edit}</p>
						<div dangerouslySetInnerHTML={{__html: this.state.data.content}}></div>
					</div>
				</div>

        <FooterContacts
          sections={this.props.sections}
          translate={this.props.translate}
        />
        
			</div>
		);
	}
}

export default Textdoc;
