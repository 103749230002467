import React, {Component} from 'react';
import {withGoogleMap, GoogleMap, Marker} from 'react-google-maps';
import iconMarker from '../../assets/img/marker.svg';

class Map extends Component {
	render() {
		const mapStyles = [
			{
				"featureType": "poi",
				"elementType": "all",
				"stylers": [
					{
						"hue": "#000000"
					},
					{
						"saturation": -100
					},
					{
						"lightness": -100
					},
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "poi",
				"elementType": "all",
				"stylers": [
					{
						"hue": "#000000"
					},
					{
						"saturation": -100
					},
					{
						"lightness": -100
					},
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "administrative",
				"elementType": "all",
				"stylers": [
					{
						"hue": "#000000"
					},
					{
						"saturation": 0
					},
					{
						"lightness": -100
					},
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "road",
				"elementType": "labels",
				"stylers": [
					{
						"hue": "#ffffff"
					},
					{
						"saturation": -100
					},
					{
						"lightness": 100
					},
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "water",
				"elementType": "labels",
				"stylers": [
					{
						"hue": "#000000"
					},
					{
						"saturation": -100
					},
					{
						"lightness": -100
					},
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "road.local",
				"elementType": "all",
				"stylers": [
					{
						"hue": "#ffffff"
					},
					{
						"saturation": -100
					},
					{
						"lightness": 100
					},
					{
						"visibility": "on"
					}
				]
			},
			{
				"featureType": "water",
				"elementType": "geometry",
				"stylers": [
					{
						"hue": "#ffffff"
					},
					{
						"saturation": -100
					},
					{
						"lightness": 100
					},
					{
						"visibility": "on"
					}
				]
			},
			{
				"featureType": "transit",
				"elementType": "labels",
				"stylers": [
					{
						"hue": "#000000"
					},
					{
						"saturation": 0
					},
					{
						"lightness": -100
					},
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "landscape",
				"elementType": "labels",
				"stylers": [
					{
						"hue": "#000000"
					},
					{
						"saturation": -100
					},
					{
						"lightness": -100
					},
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "road",
				"elementType": "geometry",
				"stylers": [
					{
						"hue": "#bbbbbb"
					},
					{
						"saturation": -100
					},
					{
						"lightness": 26
					},
					{
						"visibility": "on"
					}
				]
			},
			{
				"featureType": "landscape",
				"elementType": "geometry",
				"stylers": [
					{
						"hue": "#dddddd"
					},
					{
						"saturation": -100
					},
					{
						"lightness": -3
					},
					{
						"visibility": "on"
					}
				]
			}
		]
		
		const MapCenter = {lat: parseFloat(this.props.lat), lng: parseFloat(this.props.lng)}
		
		const GoogleMapExample = withGoogleMap(props => (
			<GoogleMap
				options={{
					styles: mapStyles,
				}}
				defaultCenter={MapCenter}
				defaultZoom={15}
			>
				<Marker icon={{url: iconMarker, scaledSize: {width: 30, height: 50}}} position={MapCenter}/>
			</GoogleMap>
		));
		
		return (
			<div className="map">
				<GoogleMapExample
					containerElement={<div style={{height: `100vh`, width: '100%'}}/>}
					mapElement={<div style={{height: `100%`}}/>}
				/>
			</div>
		);
	}
};
export default Map;
