import React from 'react';
import {NavLink} from "react-router-dom";
import './Logo.scss';
import {ReactComponent as Logo} from '../../assets/img/logo-black.svg'

class HeaderLogo extends React.Component {
	
	removeItems(){
		localStorage.removeItem('project-link');
		localStorage.removeItem('project-link-large')
	}
	
	render() {
		return (
			<NavLink className='home__link' to={'/'+this.props.lang} onClick={(e) => this.removeItems()}>
				<Logo/>
			</NavLink>
		);
	}
}
export default HeaderLogo;
