import React from 'react';
import axios from 'axios';
import { NavLink, useHistory } from "react-router-dom";
import Slider from "react-slick";
import TrackVisibility from 'react-on-screen';

import ActionBlock from '../common/Action';
import FooterContacts from '../common/Footer-contacts';
import ProjectsElements from "../common/ProjectsElements";

import {Helmet} from 'react-helmet';

import {ReactComponent as BannerArrow} from '../../assets/img/banner-arrow.svg';
import {ReactComponent as ArrowBlack} from '../../assets/img/black-arrow.svg';
import './Home.scss';

class Home extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			nav1: null,
			nav2: null,
			visible: 3,
			activeSlide: 0,
			activeSlide2: 0,
			progressBarClass: '',
			featuredSlider: [],
			featured: [],
			projects: [],
			categories: [],
			meta: [],
			textSliderAnimation: '',
			bannerTextAnimation: '',
      restoreScrollPosition: false,
		};
    this.isSafari = this.isSafari.bind(this);
	}

	componentDidMount() {
    window.onpopstate = e => {
      this.setState({
        restoreScrollPosition: true,
      })
    }

		this.setState({
			nav1: this.slider1,
			nav2: this.slider2,
			progressBarClass: 'start',
			textSliderAnimation: 'slideInUp',
			bannerTextAnimation: 'fadeIn'
		});

		axios.get(window.API + this.props.match.url)
			.then(result => {

				this.setState({
					featuredSlider: result.data.banners,
					featured: result.data.featured,
					projects: result.data.projects,
					categories: result.data.categories,
					meta: result.data.meta
				})

        if (this.state.restoreScrollPosition || this.isSafari()) {
          setTimeout(function(){
            let scrollPos = localStorage.getItem('scrollPosition') || 0;
            window.scroll(0, scrollPos);
          },250)
        }
			})
			.catch(error => alert(error));
	}

  isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  // Save scroll position
  componentWillUnmount() {
    localStorage.setItem('scrollPosition', document.body.scrollTop || document.documentElement.scrollTop);
  }

	render() {

		let about = false;
		let translate = this.props.translate;
		let sections = this.props.sections;

		sections.map((section) => {
			if (section.model === 'about') {
				about = section.about;
			}
			return about;
		})

		let settingsImg = {
			infinite: true,
			autoplaySpeed: 4000,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			pauseOnHover: false,
      swipe: false,
			beforeChange: (current, next) => this.setState({activeSlide: next, progressBarClass: ''}),
			afterChange: current => this.setState({activeSlide2: current, progressBarClass: 'start'})
		};

		let settingsText = {
			autoplay: true,
			infinite: true,
			adaptiveHeight: true,
			autoplaySpeed: 4000,
			slidesToShow: 1,
			slidesToScroll: 1,
			fade: true,
			dots: true,
			pauseOnHover: false,
			customPaging: (i) => {
				return <span>0{i + 1} <span> <b>/</b> 0{this.state.featuredSlider.length}</span></span>
			},
		};


		return (
			<div>
				<Helmet>
					<title>{this.state.meta.title}</title>
					<meta name="description" content={this.state.meta.description}/>
					<meta name="keywords" content={this.state.meta.keywords}/>

					<meta property="og:title" content={this.state.meta.title}/>
					<meta property="og:description" content={this.state.meta.description}/>
					<meta property="og:image" content={this.state.meta.image}/>
					<meta property="og:url" content={window.location.href}/>
					<meta name="twitter:card" content="summary_large_image"/>

				</Helmet>

				<div className="home__slider">

					<Slider
						asNavFor={this.state.nav2}
						ref={slider => (this.slider1 = slider)}
						{...settingsImg}>

						{this.state.featuredSlider.map(item =>
							<div key={item.id}>
								<div className="slider__item" style={{backgroundImage: `url(${item.banner})`}}>
									<div className={'banner__content container__padding animated ' + (this.state.bannerTextAnimation)}>
										<p>{translate.featured}</p>

										<NavLink to={item.link}>
											<span>
												{item.title}
												<BannerArrow/>
											</span>

										</NavLink>
									</div>

                  <div className="image" style={{backgroundImage: `url(${item.banner})`}}>
                    <NavLink to={item.link}></NavLink>
                  </div>
								</div>
							</div>
						)}
					</Slider>

					<div className={'progress-bar ' + (this.state.progressBarClass)}></div>

					<Slider
						className={'slider-text animated ' + (this.state.textSliderAnimation)}
						asNavFor={this.state.nav1}
						ref={slider => (this.slider2 = slider)}
						{...settingsText}>

						{this.state.featuredSlider.map(item =>
							<div key={item.id}>
								<div className="slider__text">
									<div className="legend container__padding">
										<div className="legend__categories">
											<p>{item.categories}</p>
										</div>

										<div className="legend__info">
											<p className="date">{item.year}</p>
											<p className="text">{item.excerpt}</p>
										</div>
									</div>
								</div>
							</div>
						)}

					</Slider>
				</div>

				<TrackVisibility partialVisibility>
					{
						({isVisible}) =>
							<NavLink to={'/' + this.props.language + '/' + translate.about_url}
											 className={'home__about container__padding animated ' + (isVisible ? 'fadeInUp' : '')}>
								<h6 className="title">{translate.about_title}</h6>
								<p><span>{translate.about_text} <ArrowBlack/></span></p>
							</NavLink>
					}
				</TrackVisibility>

					<ProjectsElements
						categories={this.state.categories}
						featured={this.state.featured}
						projects={this.state.projects}
						translate={this.props.translate}
						lang={this.props.language}
						parentProps={this.props}
            restoreScrollPosition={this.state.restoreScrollPosition}
					/>

				<ActionBlock about={about}/>

				<FooterContacts
					sections={this.props.sections}
					translate={this.props.translate}
				/>

			</div>
		);
	}
}

export default Home;
