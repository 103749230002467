import React from 'react';
import {NavLink} from "react-router-dom";

import './ProjectsElements.scss';

class ProjectsElements extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			visible: 3,
			selectedItem: -1,
			selectedProjectCategory: '',
			disableButton: false,
			language: 'lv'
		};

		this.element = React.createRef()

		this.handleFilter = this.handleFilter.bind(this);
		this.loadMore = this.loadMore.bind(this);
		this.loadMoreLink = this.loadMoreLink.bind(this)
	}

	componentDidMount() {
		let link = window.location.href.split("/").pop();

		if (this.props.lang) {
			this.setState({
				language: this.props.lang
			})
		}

    /*
		if (link === 'projekti' || link === 'projects') {
			this.setState({
				disableButton: true,
				visible: 999
			})
		}
    */
	}

  componentWillReceiveProps(nextProps) {
     if (nextProps.restoreScrollPosition) {
       this.setState({
         visible: localStorage.getItem('visibleProjects') || 3,
   		})
     }
  }

	loadMore() {
		this.setState((prev) => {
			return {visible: prev.visible + 9};
		});

    localStorage.setItem('visibleProjects', this.state.visible + 9);
	}

	loadMoreLink() {
		if (this.state.language === 'lv') {
			this.props.parentProps.history.push(this.state.language + '/projekti')
		} else {
			this.props.parentProps.history.push(this.state.language + '/projects')
		}
	}

	handleFilter(e, categoryId, selectedIndex) {
		this.setState({
			selectedProjectCategory: categoryId,
			selectedItem: selectedIndex,
      visible: 3,
		})
	}

	render() {
		let translate = this.props.translate;
		let categories = this.props.categories;
		let featured = this.props.featured;
		let projects = this.props.projects;

		let selectedFeaturedProjects = featured.filter(category => {
				if (this.state.selectedProjectCategory === '') {
					return category.category_id
				} else {
					return category.category_id === this.state.selectedProjectCategory
				}
			}
		)

		let selectedProjects = projects.filter(category => {
				if (this.state.selectedProjectCategory === '') {
					return category.category_id
				} else {
					return category.category_id === this.state.selectedProjectCategory
				}
			}
		)

		return (
			<div>
				<div className="home__projects container__margin">
					<div>
						<h2>{translate.projects_title}</h2>
					</div>

					<div>
						<ul>
							<li className={this.state.selectedItem === -1 ? 'active' : ''}>
								<p onClick={(e) => this.handleFilter(e, '', -1)}>
									{translate.projects_all}
								</p>
							</li>
							{categories.map((category, index) =>
								<li key={index} className={this.state.selectedItem === index ? 'active' : ''}>
									<p onClick={(e) => this.handleFilter(e, category.id, index)}>
										{category.title}
									</p>
								</li>
							)}
						</ul>
					</div>
				</div>

				<div className="projects__gallery container__margin">
					{selectedFeaturedProjects.map(project =>

						<div className={'item ' + project.category_id} key={project.id}>
							<NavLink to={project.link}
											 className={'element-wrap'}
											 style={{backgroundImage: `url(${project.image})`}}>
							</NavLink>

							<NavLink to={project.link} className="intro">
								<p>{project.accomplished} - {project.category}</p>
								<h2>{project.title}</h2>
								<p className="excerpt">{project.intro}</p>
							</NavLink>
						</div>
					)}
				</div>

				<div className="projects__gallery container__margin more-projects">
					{selectedProjects.slice(0, this.state.visible).map((project) => {

						return (
							<div key={project.id}
									 ref={this.elem}
									 className={'item ' + project.category_id}>
								<NavLink to={project.link}
												 className={'element-wrap'}

												 style={{backgroundImage: `url(${project.image})`}}>
									<div className="intro">
										<p>{project.accomplished} - {project.category}</p>
										<h2>{project.title}</h2>
										<p className="excerpt">{project.intro}</p>
									</div>
								</NavLink>
							</div>

						);
					})}
				</div>

				{!this.state.disableButton && (
					this.state.visible < selectedProjects.length &&
					<p onClick={this.loadMore} className="load-more">{translate.more_projects}</p>
				)}

			</div>
		);
	}
}

export default ProjectsElements;
