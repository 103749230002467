import React from 'react';

import './Action.scss';

import {ReactComponent as BannerArrow} from '../../assets/img/banner-arrow.svg'
import TrackVisibility from "react-on-screen";

class ActionBlock extends React.Component {
	
	render() {
		let about = this.props.about;
		
		if (!about) return (<div></div>);
		
		
		return (
			<TrackVisibility partialVisibility>
				{
					({isVisible}) =>
						<div className={'action__block container__padding animated ' + (isVisible ? 'fadeInUp' : '')}
							 style={{backgroundImage: `url(${about.fb_image})`}}>
							<a href={about.fb_link} target="_blank" rel="noopener noreferrer">
							<span>
								{about.fb_title}
								<BannerArrow/>
							</span>
							</a>
						</div>
				}
			</TrackVisibility>
			
		);
	}
}

export default ActionBlock;
